/* eslint-disable no-unused-vars */
import { getToken, getBtnAccess } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
    roles: [],
    buttonAccess: getBtnAccess(),
    realName: '',
    userInfo: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_DATA_ACCESS: (state, dataAccess) => {
      state.dataAccess = dataAccess
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_BTN_ACCESS: (state, btnAccess) => {
      state.buttonAccess = btnAccess
    },
    SET_REAL_NAME: (state, realName) => {
      state.realName = realName
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
    }
  },

  actions: {
  }
}

export default user

