import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// import dashboardItems from './dashboard'

import { Layout } from './helper'

export const constantRoutes = [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "chunk-main" */ '@/views/login/index'),
    hidden: true
  },

  {
    path: '/',
    component: Layout,
    redirect: '/homePage',
    children: [{
      path: '/homePage',
      name: 'HomePage',
      component: () => import(/* webpackChunkName: "chunk-main" */ '@/views/homePage/index'),
      meta: { title: '欢迎', icon: 'el-icon-s-home' }
    }]
  },
  {
    path: '/dashboard',
    component: Layout,
    children: [{
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import(/* webpackChunkName: "chunk-main" */ '@/views/dashboard/index'),
      meta: { title: '能耗概览', icon: 'el-icon-s-marketing' }
    }]
  },
  // {
  //   path: '/system',
  //   component: Layout,
  //   children: [{
  //     path: '/instMGT',
  //     name: 'InstMGT',
  //     component: () => import(/* webpackChunkName: "chunk-main" */ '@/views/system/instMGT'),
  //     meta: { title: '机构管理', icon: 'el-icon-office-building' }
  //   },{
  //     path: '/roleMGT',
  //     name: 'RoleMGT',
  //     component: () => import(/* webpackChunkName: "chunk-main" */ '@/views/system/roleMGT'),
  //     meta: { title: '角色管理', icon: 'el-icon-user' }
  //   },{
  //     path: '/userMGT',
  //     name: 'UserMGT',
  //     component: () => import(/* webpackChunkName: "chunk-main" */ '@/views/system/userMGT'),
  //     meta: { title: '用户管理', icon: 'el-icon-s-custom' }
  //   }]
  // },
  { path: '*', redirect: '/', hidden: true }
]

// export const asyncRoutes = [
//   dashboardItems,
//   {
//     path: '*',
//     name: 'Fallback',
//     redirect: '/404',
//     hidden: true
//   }
// ]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  // base: 'supervision',
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
