const EmptyPageComponent = () => import(/* webpackChunkName: "chunk-layout" */ '@/components/empty')

function nameToPath(name) {
  let temp = name.replace(/([A-Z])/g, '-$1').toLowerCase()
  if (temp.slice(0, 1) === '-') { // 如果首字母是大写，执行replace时会多一个'-'，这里需要去掉
    temp = temp.slice(1)
  }

  // return  type=='micro' ? temp+'/*' : temp
  return temp
}

/* Layout */
export const Layout = () => import(/* webpackChunkName: "chunk-layout" */ '@/layout')

export function genRouterItem(name, title, icon, hidden, component, type) {
  return {
    name,
    path: nameToPath(name, type),
    component: component || EmptyPageComponent,
    hidden: hidden || !component,
    meta: { title, icon }
  }
}
