import { constantRoutes } from '@/router'
// import { constantRoutes, asyncRoutes } from '@/router'



const permission = {
  state: {
    routers: constantRoutes,
    addRoutes: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRoutes = routers
      state.routers = constantRoutes.concat(routers)
    }
  },
  actions: {
    // GenerateRoutes({ commit }) {
    //   return new Promise(resolve => {
    //     getSidebarTreeApi().then((response) => {
    //       data = []
    //       getTree(response.data.children, 1)
    //       const path = data
    //       const accessedRouters = filterAsyncRouter(asyncRoutes, path)
    //       commit('SET_ROUTERS', accessedRouters)
    //       resolve()
    //     })
    //   })
    // }
  }
}

export default permission
