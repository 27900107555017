
export function getToken() {
}

export function setToken() {
}

export function removeToken() {
}

export function getBtnAccess() {
}

export function setBtnAccess() {
}

export function removeBtnAccess() {
}
