import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
import App from './App.vue'
import store from './store'
import router from './router'
import '@/permission' // permission control

Vue.use(ElementUI)
export const eventBus = new Vue()
Vue.config.productionTip = false
Vue.filter('currency', function(value) {
  if (!value) return 0
  return value.toString()
    .replace(/\d+/, n => n.replace(/(\d)(?=(\d{3})+$)/g, $1 => $1 + ','))
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
